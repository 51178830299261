<template name="fade">
  <div class="main-out-box">
    <!-- 一级路由出口 -->
    <router-view class="main-box"></router-view>
    <!-- 底部 -->
    <v-footer @toAbout="toLocal"></v-footer>
    <!-- 侧边栏 -->
    <v-asidebar></v-asidebar>
  </div>
</template>

<script>
import vFooter from '@/components/footer.vue'
import vAsidebar from '@/components/asidebar.vue'
export default {
  data () {
    return {
    }
  },
  components: {
    vFooter, vAsidebar
  },
  mounted () {

  },
  methods: {
    // 滑动
    toLocal () {
      //查找存储的锚点id
      let Id = localStorage.getItem("cec");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main-out-box {
  min-height: 100vh;
}

.main-box {
  min-height: 100vh;
}
</style>