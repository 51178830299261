import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 重写编程式路由导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

const routes = [
    {
        path: '/index',
        component: () => import('@/views/index.vue'),
        meta: {
            status: 1,
            title: '海南融汇南海科技有限公司「信息化服务提供商,满足客户的业务场景需求 」',
            keywords: '融汇南海、一站式服务、数字化、企业服务、政府服务、专业服务',
            description:
                '海南融汇南海科技有限公司致力于为企业及政府或相关事业单位提供一系列全方位的信息化服务，深度挖掘信息化所蕴含的商业潜力，以持续赋能合作伙伴。',
        },
    },
    {
        path: '/products',
        component: () => import('@/views/products/index.vue'),
        redirect: '/qff',
        meta: { status: 2 },
        children: [
            {
                path: '/qff',
                component: () => import('@/views/products/qff.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 1,
                    title: '企服福「一体化智能SaaS平台」助力中小微企业数字转型_海南融汇南海科技有限公司',
                    keywords: '企服福、qifufu.com.cn、SaaS、企服福SaaS平台、一体化智能SaaS平台、信息化平台、区块链电子发票、财务管理系统、企业融资服务',
                    description:
                        '海南融汇南海科技有限公司为企业提供智能数字化解决方案，平台提供增值税发票全生命周期的管理服务、企业财务管理服务、企业融资服务，企业信息化管理系统，ERP、CRM、电子合同等，助力中小微企业数字化转型。',
                },
            },
            {
                path: '/xxfp',
                component: () => import('@/views/products/xxfp.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 2,
                    title: '企服福销项系统「数字化发票管理服务」_海南融汇南海科技有限公司',
                    keywords: '数字化、自动化、降本增效、安全合规',
                    description: '数字化时代的财务管理新利器，帮助企业更好地管理财务、降低成本和风险，提高企业的经营效率和竞争力。',
                },
            },
            {
                path: '/xfzr',
                component: () => import('@/views/products/xfzr.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 3,
                    title: '小福智融「正规贷款平台」_海南融汇南海科技有限公司',
                    keywords: '小额贷款、申请简便、审批快、额度高',
                    description:
                        '小福智融小额贷款致力于为中小微企业提供融资助力，全程以线上申请办理的方式，申请简便、费用透明、审批快、额度高、到账快，努力让借款越来越便捷。',
                },
            },
            {
                path: '/dzht',
                component: () => import('@/views/products/dzht.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 4,
                    title: '电子合同、电子签名、电子签章,专业签约Saas服务平台',
                    keywords: '便捷高效、多端协同、随时签发、安全合规',
                    description: '为广大用户提供基于合法数字签名技术的电子合同和电子单据的签署及管理服务。',
                },
            },
            {
                path: '/dataCenter',
                component: () => import('@/views/products/dataCenter.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 5,
                    title: '智慧数据中心「数据可视化服务」_海南融汇南海科技有限公司',
                    keywords: '数字化、可视化、数据监控、安全合规',
                    description: '新一代数据中心可视化管理平台，让管理人员可以清晰直观地掌握有效信息，实现透明化与可视化管理，进而有效提升监管效能。',
                },
            },
            {
                path: '/integrated',
                component: () => import('@/views/products/integrated.vue'),
                meta: {
                    status: 2,
                    childrenStatus: 5,
                    title: '政务服务“一网通办”平台「一体化解决方案」_海南融汇南海科技有限公司',
                    keywords: '一体化、信息互通、协同效力、监管合力',
                    description: '整合各类应用，统一工作界面，统一访问入口，将所有业务和流程有机地融合为一个整体，形成协同效力、监管联动、监管合力，提升一体化政务服务水平。',
                },
            },
        ],
    },
    {
        path: '/news',
        component: () => import('@/views/news/index.vue'),
        meta: { status: 3 },
        redirect: '/newsList',
        children: [
            {
                path: '/newsList',
                component: () => import('@/views/news/newsList.vue'),
                meta: {
                    status: 3,
                    title: '公司动态_海南融汇南海科技有限公司',
                    keywords: '融汇南海、一站式服务、数字化、企业服务、政府服务、专业服务',
                    description:
                        '海南融汇南海科技有限公司致力于为企业及政府或相关事业单位提供一系列全方位的信息化服务，深度挖掘信息化所蕴含的商业潜力，以持续赋能合作伙伴。',
                },
            },
            {
                path: '/newsbody',
                name: 'newsbody',
                component: () => import('@/views/news/newsbody.vue'),
                meta: { status: 3, keepAlive: false },
            },
        ],
    },
    {
        path: '/about',
        component: () => import('@/views/about.vue'),
        meta: {
            status: 4,
            title: '关于我们_海南融汇南海科技有限公司',
            keywords: '融汇南海、一站式服务、数字化、企业服务、专业服务',
            description:
                '海南融汇南海科技有限公司致力于为企业及政府或相关事业单位提供一系列全方位的信息化服务，深度挖掘信息化所蕴含的商业潜力，以持续赋能合作伙伴。',
        },
    },
    {
        path: '/contact',
        component: () => import('@/views/contact.vue'),
        meta: {
            status: 5,
            title: '联系我们_海南融汇南海科技有限公司',
            keywords: '融汇南海、一站式服务、数字化、企业服务、专业服务',
            description:
                '海南融汇南海科技有限公司致力于为企业及政府或相关事业单位提供一系列全方位的信息化服务，深度挖掘信息化所蕴含的商业潜力，以持续赋能合作伙伴。',
        },
    },
    {
        path: '/problem',
        component: () => import('@/views/problem/index.vue'),
        meta: {
            status: 5,
            title: '意见反馈_海南融汇南海科技有限公司',
            keywords: '融汇南海、一站式服务、数字化、企业服务、专业服务',
            description:
                '海南融汇南海科技有限公司致力于为企业及政府或相关事业单位提供一系列全方位的信息化服务，深度挖掘信息化所蕴含的商业潜力，以持续赋能合作伙伴。',
        },
    },
    {
        path: '*',
        redirect: '/index',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes,
})

//路由前置守卫；用来设置元信息
router.beforeEach((to, from, next) => {
    if (to.meta.keywords) {
        document.querySelector("meta[name='keywords']").setAttribute('content', to.meta.keywords)
        document.querySelector("meta[name='description']").setAttribute('content', to.meta.description)
    }

    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router
