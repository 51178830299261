import axios from 'axios';
import store from '@/store';
import { Message } from 'element-ui'

axios.defaults.withCredentials = true;

// 请求拦截
axios.interceptors.request.use(
    (confing) => {
        return confing;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log('err' + error);
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error);
    }
);
export default axios;