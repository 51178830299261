<!-- 底部组件 -->
<template>
    <div v-if="isShow">
        <div class="footerbox">
            <div class="common">
                <div class="footer_top">
                    <div class="contactbox">
                        <div class="footer_logo">
                            <img src="@/assets/img/index/footer_logo.png" alt="" />
                        </div>
                        <div class="content">
                            <div class="title">海南融汇南海科技有限公司</div>
                            <div class="font">服务热线：400-617-1118</div>
                            <div class="font">商务合作：swhz@ronghuinanhai.com</div>
                        </div>
                    </div>
                    <div class="enterpriseServices">
                        <div class="title">企业服务</div>
                        <div class="content">
                            <div class="item" @click="toqff()">企服福平台</div>
                            <div class="item" @click="toxxfp()">企服福销项系统</div>
                            <div class="item" @click="todzht()">电子合同</div>
                            <div class="item" @click="toxfzr()">小福智融</div>
                        </div>
                    </div>
                    <div class="governmentServices">
                        <div class="title">政府服务</div>
                        <div class="content">
                            <div class="item" @click="toDataCenter()">智慧数据中心</div>
                            <div class="item" @click="toIntegrated()">政务服务"一网通办"平台</div>
                        </div>
                    </div>
                    <div class="cooperationbox">
                        <div class="title">合作共赢</div>
                        <div class="content">
                            <div class="item" @click="cooperationShow = true">合作共赢</div>
                        </div>
                    </div>
                    <div class="aboutUsBox">
                        <div class="title">关于我们</div>
                        <div class="content">
                            <div class="item" @click="toAbout()">公司介绍</div>
                            <div class="item" @click="toAboutItem()">企业文化</div>
                            <div class="item" @click="toContactItem()">联系我们</div>
                        </div>
                    </div>
                    <div class="followUsBox">
                        <div class="title">关注我们</div>
                        <div class="content">
                            <img class="gongzhonghao" src="@/assets/img/index/erweimaFooter.jpg" alt="" />
                            <p>关注公众号</p>
                        </div>
                    </div>
                </div>
                <div class="footer_bottom">
                    <div class="item">版权所有：海南融汇南海科技有限公司</div>
                    <div class="item">
                        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号：琼ICP备2022002951号</a>
                    </div>
                    <div class="item">
                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010602001598" target="_blank">琼公网安备46010602001641号 </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作共赢弹窗 -->
        <cooperation-dialog v-if="cooperationShow" @closeCooperationDialog="cooperationShow = false"
            @openCooperationSuccessShow="cooperationSuccessShow = true"></cooperation-dialog>
        <!-- 合作共赢成功弹窗 -->
        <cooperationsuccess-dialog v-if="cooperationSuccessShow" @closeDialog="cooperationSuccessShow = false"></cooperationsuccess-dialog>
    </div>
</template>
<script>
import cooperationDialog from '@/components/cooperation'
import cooperationsuccessDialog from '@/components/cooperationsuccess'
export default {
  components: { cooperationDialog, cooperationsuccessDialog },
  data() {
    return {
      isShow: false,
      cooperationShow: false,
      cooperationSuccessShow: false,
    }
  },
  methods: {
    // 跳转关于我们的企业文化
    toAboutItem() {
      localStorage.setItem('cec', 'cultureBox')
      this.$emit('toAbout')
      this.$router.push({ path: '/about' })
    },
    // 跳转联系我们
    toContactItem() {
      this.$router.push({
        path: '/contact',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击公司介绍跳转关于我们
    toAbout() {
      this.$router.push({
        path: '/about',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击联系我们，跳转页面
    toContact() {
      this.$router.push({
        path: '/contact',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击企服福跳转页面
    toqff() {
      this.$router.push({
        path: '/qff',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击销项发票跳转页面
    toxxfp() {
      this.$router.push({
        path: '/xxfp',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击跳转电子合同页面
    todzht() {
      this.$router.push({
        path: '/dzht',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 点击小福智融跳转页面
    toxfzr() {
      this.$router.push({
        path: '/xfzr',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    toDataCenter() {
      this.$router.push({
        path: '/dataCenter',
        query: {
          date: new Date().getTime(),
        },
      })
    },
    // 跳转一体化平台
    toIntegrated() {
      this.$router.push({
        path: '/integrated',
        query: {
          date: new Date().getTime(),
        },
      })
    },
  },
  created() {
    //延时1s渲染该组件
    setTimeout(() => {
      this.isShow = true
    }, 1000)
  },
}
</script>

<style scoped lang="scss">
.footerbox {
  width: 100%;
  height: 408px;
  background: url('@/assets/img/header/footer_bg.png') 100% 100% repeat;

  .common {
    width: 1200px;
    margin: 0 auto;

    .footer_top {
      height: 308px;
      padding: 80px 0 40px;
      box-sizing: border-box;
      border-bottom: 1px solid #373d5c;
      display: flex;
      justify-content: space-between;

      .contactbox {
        .footer_logo {
          width: 120px;
          height: 36px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          .title {
            font-size: 20px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #b4becc;
            line-height: 28px;
            margin-bottom: 20px;
          }
          .font {
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #b4becc;
            line-height: 24px;
            margin-bottom: 10px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }

      .enterpriseServices,
      .governmentServices,
      .aboutUsBox,
      .cooperationbox {
        .title {
          font-size: 16px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 700;
          color: #ffffff;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .content {
          .item {
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #b4becc;
            line-height: 24px;
            margin-bottom: 10px;
            cursor: pointer;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }

      .followUsBox {
        width: 120px;
        text-align: center;

        .title {
          font-size: 16px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 700;
          color: #ffffff;
          line-height: 24px;
          margin-bottom: 10px;
        }

        .content {
          .gongzhonghao {
            display: block;
            width: 120px;
            height: 120px;
          }

          p {
            margin-top: 10px;
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #b4becc;
            line-height: 24px;
          }
        }
      }
    }

    .footer_bottom {
      height: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        margin-right: 40px;
        font-size: 12px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        color: #96a2b2;
        line-height: 20px;

        a {
          font-size: 12px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #96a2b2;
        }
      }
    }
  }
}
</style>
