/*
 * @Author: cuicuizi
 * @Date: 2024-01-13 15:14:54
 * @Descripttion: 
 * @LastEditors: cuicuizi
 * @LastEditTime: 2024-02-03 15:29:33
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad, {
  error: require('./assets/img/contact/loading.gif'), // 加载错误的图片
  loading: require('./assets/img/contact/loading.gif') // 加载时的图片
});

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 全局引入动画库
import 'animate.css';

// 引入全局清除样式
import '@/assets/css/reset.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
