import axios from './http';
import base from './base';

// 获取区域代码树
export function getAreaCodeTree(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/areaCode/getAreaCodeTree`,
        method: 'get',
        params
    });
}

// 获取新闻分类列表
export function getNewsCategoryList(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/newsCategory/getNewsCategoryList`,
        method: 'get',
        params
    });
}

// 分页获取新闻列表
export function getNewsListByPage(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/news/getNewsListByPage`,
        method: 'post',
        data: params
    });
}

// 根据新闻id获取新闻内容
export function getNewsContentById(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/news/getNewsContentById`,
        method: 'get',
        params
    });
}

// 获取精选新闻列表
export function getFineNewsList(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/news/getFineNewsList`,
        method: 'post',
        data: params
    });
}

// 获取首页新闻列表
export function getIndexNewsList(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/news/getIndexNewsList`,
        method: 'post',
        data: params
    });
}

// 根据新闻id获取上一篇和下一篇新闻
export function getPrevious2NextNewsById(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/news/getPrevious2NextNewsById`,
        method: 'post',
        data: params
    });
}

// 获取意向客户短信验证码
export function getIntentionCustomerSmsCaptcha(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/captcha/getIntentionCustomerSmsCaptcha`,
        method: 'post',
        data: params
    });
}

// 新增意向客户
export function addIntentionCustomer(params) {
    return axios({
        url: `${base.url}/jishuiyun-portal/intentionCustomer/addIntentionCustomer`,
        method: 'post',
        data: params
    });
}