<!-- 侧边栏组件 -->
<template>
    <div>
        <!-- 侧边栏 -->
        <div class="asidebar" id="asidebar">
            <div class="first_part">
                <div class="content">
                    <div class="asideItem asideItem1">
                        <i></i>
                        <p>电话咨询</p>
                        <!-- 左侧隐藏弹窗 -->
                        <div class="asidephone">
                            <div class="left">
                                <img src="@/assets/img/index/phone.png" alt="">
                                <p class="title">
                                    全国服务电话
                                </p>
                            </div>
                            <div class="line_border"></div>
                            <div class="right">
                                <h3>400-617-1118</h3>
                            </div>
                        </div>
                    </div>
                    <div class="line_bottom"></div>
                    <div class="asideItem asideItem2">
                        <i></i>
                        <p>公众号</p>
                        <!-- 左侧隐藏弹窗 -->
                        <div class="asidepublic">
                            <img src="@/assets/img/index/aside-erweima.png" alt="">
                            <div class="info">
                                <p> 扫码关注</p>
                                <p>融汇南海公众号</p>
                            </div>
                        </div>
                    </div>
                    <div class="line_bottom"></div>
                    <div class="asideItem asideItem3" @click="cooperationShow = true">
                        <i></i>
                        <p>合作共赢</p>
                    </div>
                    <div class="line_bottom"></div>
                    <div class="asideItem asideItem5" @click="toProblemPage">
                        <i></i>
                        <p>意见反馈</p>
                    </div>
                </div>
            </div>
            <div class="second_part">
                <div class="asideItem4" @click="toTop">
                    <i></i>
                    <p class="label"> 回到顶部</p>
                </div>
            </div>
        </div>
        <!-- 合作共赢弹窗 -->
        <v-cooperation v-if="cooperationShow" @closeCooperationDialog="cooperationShow = false"
            @openCooperationSuccessShow="cooperationSuccessShow = true"></v-cooperation>
        <!-- 合作共赢提交成功弹窗 -->
        <v-cooperationsuccess v-if="cooperationSuccessShow" @closeDialog="cooperationSuccessShow = false"></v-cooperationsuccess>
    </div>
</template>

<script>
import vCooperation from '@/components/cooperation.vue'
import vCooperationsuccess from '@/components/cooperationsuccess.vue'
export default {
  data() {
    return {
      cooperationSuccessShow: false, //合作共赢提交成功弹窗
      cooperationShow: false, //合作共赢弹窗
    }
  },
  components: {
    vCooperation,
    vCooperationsuccess,
  },
  methods: {
    // 返回顶部
    toTop() {
      let timer = setInterval(function () {
        //获取滚动条的滚动高度
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        //用于设置速度差，产生缓动的效果
        var speed = Math.floor(-scrollTop / 4)
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed //用纯数字赋值
        if (scrollTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    },
    // 跳转到意见反馈页面
    toProblemPage() {
      // 从新页面打开‘意见反馈’页面
      window.open('/problem')
    },
  },
  mounted() {
    // 给页面加滚动监听事件，滚动条高度大于多少时展示侧边栏
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 400) {
        document.getElementById('asidebar').style.display = 'block'
      } else if (window.scrollY <= 400) {
        document.getElementById('asidebar').style.display = 'none'
      }
    })
  },
}
</script>

<style scoped lang="scss">
/* 侧边栏 */
.asidebar {
  display: none;
  position: fixed;
  top: 40%;
  bottom: 20%;
  right: 20px;
  width: 96px;
  height: auto;
  text-align: center;
  z-index: 100;
  .first_part {
    height: 416px;
    background: #3377ff;
    box-shadow: 0px 8px 20px 0px rgba(51, 119, 255, 0.302);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    .asideItem {
      width: 56px;
      height: 64px;
      margin-bottom: 21px;
      color: #ffffff;
      cursor: pointer;

      i {
        display: inline-block;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      p {
        height: 24px;
        font-size: 14px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .line_bottom {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 56px;
      height: 1px;
      background: #85adff;
    }
    .asideItem1,
    .asideItem2 {
      position: relative;
    }

    .asideItem1 {
      i {
        width: 30px;
        height: 30px;
        background-image: url('@/assets/img/index/aside-dianhuazixunicon.png');
      }

      .asidephone {
        display: none;
        width: 190px;
        height: 92px;
        background-image: url('@/assets/img/index/aside_01.png');
        position: absolute;
        left: -221px;
        top: -7px;
        padding: 13px 20px;
        .left {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          img {
            margin-right: 6px;
            height: 16px;
            width: 16px;
          }
          .title {
            height: 24px;
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #667180;
            line-height: 24px;
          }
        }
        .line_border {
          width: 140px;
          height: 1px;
          background: #f0f2f5;
        }
        .right {
          margin-top: 6px;
          h3 {
            height: 28px;
            font-size: 20px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #0d131a;
            line-height: 28px;
          }
        }
      }
    }

    .asideItem1:hover .asidephone {
      display: block;
    }

    .asideItem2 {
      i {
        width: 30px;
        height: 30px;
        background-image: url('@/assets/img/index/aside-gongzhonghaoicon.png');
      }

      .asidepublic {
        display: none;
        position: absolute;
        background-image: url('@/assets/img/index/aside_02.png');
        top: -36px;
        left: -181px;
        width: 150px;
        height: 190px;
        padding: 10x 10px 12px 10px;
        img {
          width: 120px;
          height: 120px;
        }
        .info {
          height: 48px;
          p {
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #667180;
            text-align: center;
          }
        }
      }
    }

    .asideItem2:hover .asidepublic {
      display: block;
    }

    .asideItem3 {
      i {
        width: 30px;
        height: 30px;
        background-image: url('@/assets/img/index/aside-problem.png');
      }
    }

    .asideItem5 {
      i {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/img/index/noticeicon.png');
        background-size: 100%;
        cursor: pointer;
      }
    }
  }
  .second_part {
    margin-top: 20px;
    height: 104px;
    background: #3377ff;
    box-shadow: 0px 8px 20px 0px rgba(51, 119, 255, 0.302);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .asideItem4 {
      i {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/img/index/aside-zhidingicon.png');
        cursor: pointer;
      }
      .label {
        height: 24px;
        font-size: 14px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }
}
</style>