/*
 * @Author: cuicuizi
 * @Date: 2024-01-13 15:14:54
 * @Descripttion: 
 * @LastEditors: cuicuizi
 * @LastEditTime: 2024-01-13 15:29:25
 */
if (process.env.NODE_ENV == 'production') {
  var base = {
    url: 'https://www.ronghuinanhai.com/portal',
  };
} else if (process.env.NODE_ENV == 'development') {
  var base = {
    url: 'http://106.119.167.89:19010',
  };
} else if (process.env.NODE_ENV == 'test') {
  var base = {
    url: 'https://test-www.ronghuinanhai.com/portal',
  };
}
export default base;