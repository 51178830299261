<!--
 * @Author: cuicuizi
 * @Date: 2024-01-13 15:14:54
 * @Descripttion: 
 * @LastEditors: GaoHaHa
 * @LastEditTime: 2024-02-02 17:20:25
-->
<template>
    <div class="cooperationSuccessBox">
        <el-dialog :visible.sync="cooperationSuccessShow" width="520px" :show-close="false">
            <div class="contentBox">
                <div class="imgBox">
                    <img src="@/assets/img/problem/success.png" alt="" />
                </div>
                <div class="title">提交成功</div>
                <div class="font">我们的工作人员将尽快联系您，请保持电话畅通！</div>
                <div class="goBackBox">
                    <div class="goBackBtn" @click="closeDialog">返回</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cooperationSuccessShow: true,
        }
    },
    mounted() {},
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
    },
}
</script>
<style scoped lang="scss">
.cooperationSuccessBox {
    .contentBox {
        padding-top: 50px;
        text-align: center;

        .imgBox {
            width: 120px;
            height: 120px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .title {
            margin: 40px 0 10px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #0d131a;
            line-height: 34px;
        }

        .font {
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 400;
            color: #667180;
            line-height: 24px;
        }

        .goBackBox {
            width: 120px;
            height: 40px;
            margin: 40px auto 0;
            border: 2px solid #3377ff;
            border-radius: 4px;

            .goBackBtn {
                font-size: 16px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 700;
                color: #3377ff;
                line-height: 36px;
                text-align: center;
            }
        }
    }
}
</style>

<style lang="scss">
.cooperationSuccessBox {
    .el-dialog {
        width: 520px;
        height: 408px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        margin-top: 32vh !important;

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__body {
            padding: 0;
        }
    }
}
</style>
