<template>
    <div class="cooperationBox">
        <el-dialog :visible.sync="cooperationShow" width="660px" :before-close="cancel" @close="closeDialog" :close-on-click-modal="false">
            <div class="title">
                <h3>申请成为融汇南海的合作伙伴</h3>
            </div>
            <el-form :rules="cooperationRules" ref="cooperationForm" label-position="right" label-width="107px" :model="cooperationForm">
                <el-form-item label="所在区域：" prop="countyCode">
                    <el-cascader :options="industryTree" clearable v-model="cooperationForm.countyCode" placeholder="您所在的区域"
                        :props="industryTreeProps"></el-cascader>
                </el-form-item>
                <el-form-item label="公司名称：" prop="enterpriseName">
                    <el-input clearable v-model.trim="cooperationForm.enterpriseName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="customerName">
                    <el-input clearable v-model.trim="cooperationForm.customerName" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="customerMobile">
                    <el-input clearable v-model.number="cooperationForm.customerMobile" placeholder="请输入手机号码" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="短信验证码：" prop="captcha" class="captchaBox">
                    <el-input v-model.trim="cooperationForm.captcha" placeholder="请输入验证码" maxlength="6"> </el-input>
                    <i class="line"></i>
                    <el-button @click="getCaptcha" :disabled="disabledBtn">{{ btnText }}</el-button>
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button type="primary" @click="applyFor()">立即申请</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import areaTree from '@/assets/js/areaTree.json'
import { getIntentionCustomerSmsCaptcha, addIntentionCustomer } from '@/api/api'
export default {
  data() {
    return {
      btnText: '获取验证码', //获取验证码按钮的文字信息
      disabledBtn: false, //获取验证码按钮的是否禁用
      cooperationShow: true,
      industryTree: [], //区域数据列表
      industryTreeProps: {
        value: 'id',
        label: 'name',
        children: 'children',
      },
      cooperationForm: {
        captcha: '', //验证码
        cityCode: '', //市码
        cityName: '', //市名
        countyCode: '', //区县码
        countyName: '', //区县名
        customerMobile: '', //客户手机号码
        customerName: '', //客户姓名
        customerSource: 0, //客户来源  1 官网首页，2 销项产品页，3 小福智融产品页
        enterpriseName: '', //企业名称
        provinceCode: '', //省码
        provinceName: '', //省名
      },
      cooperationRules: {
        countyCode: [{ required: true, message: '请选择所在区域', trigger: 'blur' }],
        enterpriseName: [{ required: true, message: '请输入公司名称', trigger: 'change' }],
        customerName: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        customerMobile: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入正确验证码', trigger: 'blur' },
        ],
      },
    }
  },
  components: {},
  methods: {
    // 立即申请
    applyFor() {
      this.$refs['cooperationForm'].validate(async (valid) => {
        if (!valid) {
          return false
        }

        let provinceCode = this.cooperationForm.countyCode[0] //省码
        let cityCode = this.cooperationForm.countyCode[1] //市码
        let countyCode = this.cooperationForm.countyCode[2] //区县码

        let provinceArr = this.industryTree.filter((item) => {
          return item.id == provinceCode
        })
        this.cooperationForm.provinceName = provinceArr[0].name //省名

        let cityArr = provinceArr[0].children.filter((item) => {
          return item.id == cityCode
        })
        this.cooperationForm.cityName = cityArr[0].name //市名

        let countyArr = cityArr[0].children.filter((item) => {
          return item.id == countyCode
        })
        this.cooperationForm.countyName = countyArr[0].name //区县名

        this.cooperationForm.provinceCode = provinceCode //省码
        this.cooperationForm.cityCode = cityCode //市码
        this.cooperationForm.countyCode = countyCode //区县码

        if (this.$route.path == '/xxfp') {
          this.cooperationForm.customerSource = 2
        } else if (this.$route.path == '/xfzr') {
          this.cooperationForm.customerSource = 3
        } else {
          this.cooperationForm.customerSource = 1
        }

        this.cooperationForm.customerMobile = String(this.cooperationForm.customerMobile)

        const { data } = await addIntentionCustomer(this.cooperationForm)
        // console.log(data);
        if (data.code == 200) {
          this.cooperationShow = false
          this.$emit('openCooperationSuccessShow')

          this.$refs.cooperationForm.resetFields()
          this.cooperationForm = {
            captcha: '', //验证码
            cityCode: '', //市码
            cityName: '', //市名
            countyCode: '', //区县码
            countyName: '', //区县名
            customerMobile: '', //客户手机号码
            customerName: '', //客户姓名
            customerSource: 0, //客户来源  1 官网首页，2 销项产品页，3 小福智融产品页
            enterpriseName: '', //企业名称
            provinceCode: '', //省码
            provinceName: '', //省名
          }
        } else {
          this.$message.error(data.message)
        }
      })
    },
    // 点击获取验证码
    async getCaptcha() {
      if (!this.cooperationForm.customerMobile) {
        this.$message.error('手机号不能为空')
        return
      }

      let phone = String(this.cooperationForm.customerMobile)

      const { data } = await getIntentionCustomerSmsCaptcha({
        mobile: phone,
      })
      // console.log(data);
      if (data.code == 200) {
        this.disabledBtn = true
        let num = 59

        this.btnText = num + 's'
        let coutdown = setInterval(() => {
          if (num > 0) {
            this.btnText = num + 's'
            --num
          } else {
            this.btnText = '获取验证码'
            this.disabledBtn = false
            clearInterval(coutdown)
          }
        }, 1000)
      } else {
        console.log('获取失败')
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('closeCooperationDialog')
    },
    // 关闭按钮
    cancel() {
      this.cooperationShow = false
      this.$refs.cooperationForm.resetFields()
      this.cooperationForm = {
        captcha: '', //验证码
        cityCode: '', //市码
        cityName: '', //市名
        countyCode: '', //区县码
        countyName: '', //区县名
        customerMobile: '', //客户手机号码
        customerName: '', //客户姓名
        customerSource: 0, //客户来源  1 官网首页，2 销项产品页，3 小福智融产品页
        enterpriseName: '', //企业名称
        provinceCode: '', //省码
        provinceName: '', //省名
      }
    },
  },
  created() {
    this.industryTree = areaTree
  },
}
</script>
<style scoped lang="scss">
.cooperationBox {
  .title {
    height: 88px;
    padding-top: 60px;
    background: url('@/assets/img/header/dialogTitleBg.png') no-repeat;
    background-position: left top;

    h3 {
      text-align: center;
      font-size: 20px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 700;
      color: #0d131a;
      line-height: 28px;
    }
  }

  .footer {
    width: 240px;
    height: 58px;
    padding-top: 10px;
    margin: 0 auto;
  }
}
</style>
<style lang="scss">
.cooperationBox {
  .el-dialog {
    width: 660px;
    height: 566px;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        font-size: 20px;

        .el-dialog__close {
          width: 20px;
          height: 20px;
          font-size: 20px;
        }
      }
    }

    .el-dialog__body {
      padding: 0;

      .el-form {
        margin-top: 40px;
        padding: 0 82px;

        .el-form-item {
          margin-bottom: 30px;

          .el-form-item__content {
            width: 389px;

            .el-cascader {
              width: 100%;
            }
          }
        }

        .captchaBox {
          .el-form-item__content {
            width: 389px;
            background-color: #fff;
            border: 1px solid #e1e3e5;
            border-radius: 4px;

            .el-input {
              width: 281px;

              .el-input__inner {
                border: none;
              }
            }

            .line {
              display: inline-block;
              width: 1px;
              height: 20px;
              background-color: #e1e3e5;
              margin: -5px 20px -5px 5px;
            }

            .el-button {
              width: 70px;
              height: 40px;
              border: none;
              padding: 0;

              span {
                font-size: 14px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 400;
                color: #3377ff;
                line-height: 24px;
              }
            }

            .el-button:hover {
              background-color: #fff;
            }
          }
        }
      }

      .footer {
        .el-button {
          width: 240px;
          height: 48px;
          background: #3377ff;
          box-shadow: 0px 8px 20px 0px rgba(51, 119, 255, 0.2);
          border-radius: 4px 4px 4px 4px;

          span {
            font-size: 14px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
